export default {
  methods: {
    phoneNumberWithoutFormat(phone) {
      const digitsOnly = phone.replace(/\D/g, "");
      return digitsOnly;
    },
    formatPhoneNumber(phone) {
      if (!phone) {
        return null;
      }
      const onlyNumbers = phone.replace(/\D/g, "");
      const regex = /(^1)?(\d{0,3})(\d{0,3})(\d{0,4})/;
      const match = regex.exec(onlyNumbers);
      if (match) {
        const intlCode = match[1] ? "1" : "";
        const first = match[2];
        const second = match[3];
        const third = match[4];
        if (first && second && third) {
          return `${intlCode}(${first}) ${second}-${third}`;
        }
        if (first && second) {
          return `${intlCode}(${first}) ${second}`;
        }
        if (first) {
          return `${intlCode}(${first}`;
        }
        return intlCode;
      }
      return "";
    },
  },
};
