<template>
  <div class="not-found">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="not-found__icon"
      width="80"
      height="80"
      viewBox="0 0 150 150"
    >
      <path
        id="Union_4"
        data-name="Union 4"
        d="M1.934,148.066a6.6,6.6,0,0,1,0-9.339l21.644-21.644q-1.967-2.4-3.7-4.966a66.663,66.663,0,0,1,92.555-92.555q2.562,1.731,4.966,3.7L138.726,1.934a6.6,6.6,0,0,1,9.34,9.339L126.736,32.6q1.967,2.4,3.7,4.966a66.663,66.663,0,0,1-92.555,92.555q-2.562-1.731-4.966-3.7L11.273,148.066a6.6,6.6,0,0,1-9.339,0Zm73.223-20.393a52.8,52.8,0,0,0,41.718-85.209l-74.1,74.1A52.571,52.571,0,0,0,75.157,127.673ZM22.327,74.843a52.571,52.571,0,0,0,11.112,32.379l74.1-74.1a52.8,52.8,0,0,0-85.21,41.718Z"
        transform="translate(0 0)"
        fill="#1e3051"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="scss" scoped>
.not-found {
  margin: 6% auto 0 auto;
  &__icon {
    margin-bottom: 10px;
  }
}
</style>
