export default {
  data() {
    return {
      scrollableDivsElements: [],
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.addScrollableDivProperties);
  },
  methods: {
    setupScrollableDiv(auxDomElement, auxOffsetTop = 0, auxOffsetBottom = 0) {
      const newScrollableDiv = {
        domElement: auxDomElement,
        offsetTop: auxOffsetTop,
        offsetBottom: auxOffsetBottom,
      };
      this.scrollableDivsElements.push(newScrollableDiv);
      this.addScrollableDivProperties();
      window.addEventListener("resize", this.addScrollableDivProperties);
    },
    addScrollableDivProperties() {
      this.scrollableDivsElements.forEach((element) => {
        const { domElement, offsetTop, offsetBottom } = element;
        const setupHeight = (window.innerHeight - (offsetTop * 1.4)) - offsetBottom;
        domElement.style.height = `${setupHeight}px`;
        domElement.style.maxHeight = `${setupHeight}px`;
      });
    },
  }
};
