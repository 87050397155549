<template>
  <div class="table-header">
    <div class="table-header-content">
      <template v-for="item in fields">
        <div
          :class="'table-header-titles--' + item.label"
          class="table-header-titles"
          :style="styles"
          :key="item.label"
        >
          {{ $t("accountTable." + item.label) }}
          <!-- <span v-if="item.popover" class="popover-container">
            <button class="tooltip-icon" type="button" id="popover-button-disable-event">
              <i class="ion ion-information-circle-outline"></i>
            </button>
            <b-popover
              ref="popover"
              target="popover-button-disable-event"
              triggers="hover"
              placement="bottom"
            >
              <p>{{ item.popover }}</p>
            </b-popover>
          </span> -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableHeader",
  props: {
    fields: Array,
    styles: Object,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.table-header {
  padding: 0px 15px;
}

.table-header-content {
  align-items: center;
  border-radius: 15px;
  border: 2px solid $color-border-container;
  background-color: $color-gray-light;
  color: $color-primary-company;
  display: flex;
  font-weight: bold;
  flex-direction: row;
  padding-top: 1%;
  padding-bottom: 1%;
  height: 50px;
}
.table-header-titles {
  @include font-small-standart-text;
  font-weight: bold;
  display: flex;
  justify-content: left;
  margin-left: 1rem;
  color: $color-primary-company;
  border-right: 2px solid $color-border-container;
  &:nth-last-child(1) {
    border-right: none;
  }
  &--weight {
    width: 7% !important;
  }
  &--nfm_class {
    width: 7% !important;
  }
  &--nmfc {
    width: 12% !important;
  }
  &--hazardous_materials {
    width: 10% !important;
  }
}

button {
  border: none;
  padding: 0;
  color: $color-primary-company;
  cursor: initial !important;
}
</style>
