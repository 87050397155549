<template>
  <div class="table-rows-container" @wheel="wheelEventHandling" ref="table-rows-container">
    <div class="rows-container" ref="rows-container">
      <div
        class="table-row"
        :id="item.id"
        v-for="item in data"
        :key="item.id"
        :ref="item.id == $route.params[pathIdParam] ? 'selected' : undefined"
      >
        <div
          class="table-row-content"
          @click="selectOption(item.id, item)"
          :class="selectedRow === item.id ? selectedOption : ''"
        >
          <div
            class="table-row-content__item"
            :style="styles"
            v-for="(value, index) in dataKeys"
            :key="index"
          >
            {{ item[value] }}
          </div>
        </div>
      </div>
      <slot name="loading-container"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableRowContainer",
  props: {
    data: Array,
    styles: Object,
    dataKeys: Array,
    selectedItem: Object,
    firstElement: {
      type: Object,
      required: false,
    },
    pathIdParam: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedRow: null,
      selectedOption: null,
      currentPage: 1,
      perPage: 24,
      table: null,
    };
  },
  watch: {
    selectedItem() {
      this.selectOption(this.selectedItem.id, this.selectedItem);
    },
  },
  created() {
    if (this.selectedItem) {
      this.selectOption(this.selectedItem.id, this.selectedItem);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.table = document.querySelector(".table-rows-container");
    });
  },
  methods: {
    wheelEventHandling() {
      if (
        Math.ceil(this.table.scrollTop + this.table.clientHeight)
        >= this.table.scrollHeight - 100
      ) {
        this.$emit("loadMoreItems");
      }
    },
    selectOption(id, items) {
      this.selectedRow = id;
      this.selectedOption = "selected";
      this.$emit("selectedElement", items);
    },
    scrollToElement() {
      this.$nextTick(() => {
        const [el] = this.$refs.selected;
        if (el) {
          el.scrollIntoView({ block: "center", behavior: "smooth" });
        }
      });
    },
    scrollToStartElements() {
      const table = document.querySelector(".table-rows-container");
      table.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-rows-container {
  padding: 0px 1rem;
  @extend %scroll-bar-styles;
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - 50px);
  // margin-bottom: 2rem;
}

.rows-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 16px;
  &:last-child {
    padding-bottom: 4px;
  }
}

.table-row-content {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  background: $color-white;
  border: 1px solid $color-border-container;
  cursor: pointer;
  box-shadow: 5px 4px 5px -3px $color-gray;
  &__item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    margin-left: 1rem;
    font-size: large;
  }
}

.selected {
  background: $color-border-container;
  border: none;
  color: $color-white;
}
</style>
