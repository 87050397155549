export default {
  data() {
    return {
      screenWidth: null,
    };
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", this.onReSize);
  },
  methods: {
    onReSize() {
      this.screenWidth = window.innerWidth;
    },
  },
};
