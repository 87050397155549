<template>
  <div class="accordion" role="tablist" @touchmove="handleTouchmoveEvent">
    <div v-if="noUsers" class="noUsers">{{ $t("userManagement.noData") }}</div>
    <div v-for="item in dataDetail" :key="item.id" class="row-content mb-1" no-body>
      <div
        class="header"
        header-tag="header"
        role="tab"
        v-b-toggle="'collapse-information-' + item.id"
      >
        <div class="header-information" :class="item.collapsed">
          <p class="header-information__name" v-if="$route.name === 'Accounts'">
            {{ item.company_name || item.name }}
          </p>
          <p class="header-information__name" v-else>
            {{ item.name || item.username }}
          </p>
          <p class="header-information__company-name" v-if="$route.name === 'Accounts'">
            {{ item.company_address }}
          </p>
          <p class="header-information__company-name" v-else>
            {{ item.role ? item.role_name : "" || item.email }}
          </p>
        </div>
        <div class="header-status">
          <span
            v-if="$route.name === 'UserManagement'"
            :class="item.status ? 'active' : 'inactive'"
          >
            {{ item.status ? "Active" : "Inactive" }}
          </span>
          <div class="header-icon">
            <i
              :class="
                item.collapsed ? 'ion ion-chevron-forward-outline' : 'ion ion-chevron-down-outline'
              "
            ></i>
          </div>
        </div>
      </div>
      <b-collapse
        :id="'collapse-information-' + item.id"
        class="body"
        accordion="my-accordion"
        role="tabpanel"
        @show="showCard(item)"
        @hide="hideCard(item)"
      >
        <div class="account-information"></div>
        <div class="account-information__detail" v-if="!item.collapsed">
          <slot name="collapsable-detail" :item="item"> </slot>
        </div>
      </b-collapse>
    </div>
    <slot name="loading-container"></slot>
  </div>
</template>

<script>
export default {
  name: "RowCollapse",
  props: {
    data: Array,
    allAccountsAreLoaded: Boolean,
    accountsSearchText: String,
  },
  data() {
    return {
      dataDetail: null,
      componentShow: "",
      selectedAccount: null,
      allInfo: null,
      accountId: null,
      noUsers: false,
      table: null,
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        if (this.data) {
          this.buildCustomData(this.data);
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.table = document.querySelector(`.accordion`);
    });
  },
  computed: {
    informationDetail() {
      return this.$route.name;
    },
  },
  methods: {
    handleTouchmoveEvent() {
      if (this.accountsSearchText) {
        return;
      }
      if (
        Math.ceil(this.table.scrollTop + this.table.clientHeight) >=
        this.table.scrollHeight - 100
      ) {
        this.$emit("loadMoreItems");
      }
    },
    buildCustomData(data) {
      this.dataDetail = data.map((item) => ({
        ...item,
        is_checked: false,
        is_active: item.is_active ? "Inactive" : "Active",
        collapsed: true,
        isOpen: false,
      }));
    },
    async showCard(item) {
      if (this.$route.name.toLowerCase() === "accounts") {
        this.accountIdRoute(item);
      } else {
        this.userIdRoute(item);
      }
      this.selectedAccount = item;
      item.collapsed = false;
      this.$emit("selectedAccount", {
        selectedAccount: item,
      });
    },
    accountIdRoute(data) {
      const params = { accountId: data.id };
      this.$router.push({ params });
    },
    userIdRoute(data) {
      if (data) {
        const params = { userId: data.id };
        this.$router.push({ params }).catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      }
    },
    deletedUser(id) {
      this.$emit("removeUser", id);
    },
    hideCard(item) {
      item.collapsed = true;
    },
    async changeView(routeName) {
      await this.$router.push({
        name: routeName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  height: 75vh;
  padding: 2px;
  overflow: auto;
  @extend %scroll-bar-styles;
}

.accordion::-webkit-scrollbar {
  display: none;
}

.row-content {
  @include collapse-container-styles;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .header-information {
    &__name {
      display: flex;
      align-items: center;
      margin: 0px;
      font-weight: bold;
    }
    &__company-name {
      margin: 0px;
      text-align: start;
    }
  }
  .header-status {
    display: flex;
    .active {
      color: #00ff00;
    }
    .inactive {
      color: red;
    }
    span {
      margin-right: 25px;
      font-weight: 600;
    }
  }
  .header-icon {
    font-size: 25px;
  }
}
.noUsers {
  color: $color-primary-company;
}
.body {
  margin-top: 10px;
  font-weight: bold;
  .account-information {
    &__detail {
      border-top: 1px solid $color-border-container;
      padding: 20px 0px;
    }
    button {
      @include primary-button(40px);
      text-align: start;
      width: auto;
      @include font-small-button-text;
      border-radius: 15px;
      margin: 0;
      padding: 0 10px;
    }
  }
}
.not-collapsed .header .header-icon {
  transform: rotate(90deg);
  transition: 0.4s all;
}
.collapsed .header .header-icon {
  transform: rotate(0deg);
  transition: 0.6s all;
}
</style>
